import React, { FunctionComponent } from 'react'

const AnimatedCircles: FunctionComponent = (): JSX.Element => {
  return (
    <div className="relative">
      <div className="absolute top-10 md:-top-28 left-0 md:-left-5 w-44 h-44 md:w-72 md:h-72 bg-gradient-radial bg-amber-300 md:bg-secondary-300 rounded-full -z-10 mix-blend-hard-light dark:mix-blend-soft-light filter blur-xl opacity-40 dark:opacity-30 animate-blob-3"></div>
      <div className="absolute top-20 right-10 md:-right-40 w-52 h-52 md:w-80 md:h-80 bg-secondary-300 md:bg-amber-300 rounded-full -z-10 mix-blend-hard-light dark:mix-blend-soft-light filter blur-xl opacity-40 dark:opacity-30 animate-blob animation-delay-4000"></div>
      <div className="absolute top-44 left-10 md:left-60 w-72 h-72 md:w-96 md:h-96 bg-primary-300 rounded-full -z-10 mix-blend-hard-light dark:mix-blend-soft-light filter blur-xl opacity-40 dark:opacity-30 animate-blob-2 animation-delay-2000"></div>
    </div>
  )
}

export default AnimatedCircles
