import { classNames } from 'lib/utils/classnames'
import { FunctionComponent } from 'react'
import AnimatedCircles from './AnimatedCircles'
import Footer from './Footer'
import Header from './Header'

interface ContainerProps {
  showCircles?: boolean
}

const Container: FunctionComponent<ContainerProps> = ({ children, showCircles = false }) => {
  return (
    <div
      className={classNames('min-h-screen', showCircles && 'overflow-hidden sm:overflow-visible')}
    >
      <Header />
      <main className="flex flex-col mt-36 mx-auto w-full max-w-5xl justify-center px-6 relative">
        {showCircles && <AnimatedCircles />}
        <div className="z-10">{children}</div>
      </main>
      <Footer />
    </div>
  )
}

export default Container
