export enum Form {
  Initial,
  Loading,
  Success,
  Error,
}

export type FormState = {
  state: Form
  message?: string
}

export type INewsletterSize = 'small' | 'large'

export interface INewsLetterStats {
  totalIssues?: number
  totalSubscribers?: number
  error?: string
}
