/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-has-content */
import { trackEvent } from 'lib/utils/analytics'
import { classNames } from 'lib/utils/classnames'
import { isDesktop } from 'lib/utils/constants'
import Link from 'next/link'
import { AnchorHTMLAttributes, DetailedHTMLProps, useState } from 'react'
import ExternalLinkIcon from './icons/svg-icons/external-link.svg'
import Image from './Image'
import Placeholder from './Placeholder'

const excludedLinks = [
  'twitter.com',
  'linkedin.com',
  'akashrajpurohit.com',
  'akashrajpurohit.cf',
  'youtube.com',
  'youtu.be',
  'instagram.com',
  'vercel.com',
  'amzn.to',
  'amazon.in',
  'infosys.com',
  'hackerrank.com',
  'vcet.edu.in',
  'zerodha.com',
  'indmoney',
  'binance',
]

const CustomLink = ({
  href,
  showExternalIcon = true,
  showExternalPreview = true,
  className = '',
  children,
  ...props
}: DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  showExternalIcon?: boolean
  showExternalPreview?: boolean
}) => {
  const [screenshot, setScreenshot] = useState({ image: null, error: null, haveRequested: false })

  const isInternalLink = href && href.startsWith('/')
  const isAnchorLink = href && href.startsWith('#')
  const isExcluded =
    !showExternalPreview || excludedLinks.some((excludedLink) => href.includes(excludedLink))

  const trackAffiliateLinks = (link: string) => {
    const affiliates = ['amzn.to', 'amazon', 'amazon', 'zerodha', 'indmoney', 'binance']
    const bannedList = ['aws.amazon.com/free']
    const isAffiliateLink =
      bannedList.includes(link) && affiliates.some((affiliateLink) => link.includes(affiliateLink))

    if (isAffiliateLink) {
      trackEvent({ name: 'Affiliate Link', data: { link } })
    }
  }

  const handleHover = async () => {
    if (isExcluded || isInternalLink || isAnchorLink || !isDesktop()) {
      setScreenshot((ss) => ({ ...ss, error: 'Excluded this' }))
      return
    }

    // Already have the image, let's not make another call.
    // Or we have already requested for it.
    if (screenshot.image || screenshot.haveRequested) {
      return
    }

    try {
      setScreenshot((ss) => ({ ...ss, haveRequested: true }))

      const response = await fetch(
        `https://ss.akashrajpurohit.com/simple-screenshot?url=${href}&w=1280&h=1024`,
        {
          method: 'GET',
        }
      )

      const { error, image } = (await response.json()) as { error?: string; image?: string }

      if (error) {
        setScreenshot((ss) => ({ ...ss, error }))
        return
      }

      setScreenshot((ss) => ({ ...ss, error: null, image }))
    } catch (error) {
      setScreenshot((ss) => ({ ...ss, error }))
    }
  }

  if (isInternalLink) {
    return (
      <Link href={href}>
        <a className={className} {...props}>
          {children}
        </a>
      </Link>
    )
  }

  if (isAnchorLink) {
    return (
      <a href={href} className={className} {...props}>
        {children}
      </a>
    )
  }

  return (
    <span className="group relative z-50 h-full" onMouseOver={handleHover} onFocus={handleHover}>
      {!isExcluded && !screenshot.error && showExternalPreview && (
        <span className="pointer-events-none absolute left-0 bottom-full ml-[50%] md:flex -translate-x-2/4 -translate-y-0 rounded-lg bg-white p-2 opacity-0 shadow-lg transition-all group-hover:-translate-y-2 group-hover:opacity-100 dark:bg-gray-700 hidden z-50">
          {screenshot.image ? (
            <Image
              src={`data:image/png;base64,${screenshot.image}`}
              width={300}
              height={240}
              layout="fixed"
              alt=""
            />
          ) : (
            <Placeholder className="h-[240.5px] w-[300px] rounded-md" />
          )}
        </span>
      )}
      <Link href={href} passHref>
        <a
          {...props}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames('inline text-md font-normal transition-colors', className)}
          onClick={() => trackAffiliateLinks(href)}
        >
          {children}
          {showExternalIcon && (
            <span className="ml-0.5 h-4 w-5 inline-block text-primary-600 dark:text-primary-500">
              <ExternalLinkIcon />
            </span>
          )}
        </a>
      </Link>
    </span>
  )
}

export default CustomLink
