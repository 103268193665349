import siteMetadata from './siteMetadata'

const { pages } = siteMetadata

const headerNavLinks = [
  { href: pages.home, title: 'Home' },
  { href: pages.blogs, title: 'Blogs' },
  { href: pages.projects, title: 'Projects' },
  { href: pages.about, title: 'About' },
]

export default headerNavLinks
