import React from 'react'
import { Loader } from 'react-feather'

import { classNames } from 'lib/utils/classnames'

enum ButtonVariant {
  'primary',
  'secondary',
  'outline',
  'ghost',
  'light',
  'dark',
}

type ButtonProps = {
  isLoading?: boolean
  variant?: keyof typeof ButtonVariant
} & React.ComponentPropsWithRef<'button'>

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, className, disabled: buttonDisabled, isLoading, variant = 'primary', ...rest },
    ref
  ) => {
    const disabled = isLoading || buttonDisabled

    return (
      <button
        ref={ref}
        type="button"
        disabled={disabled}
        className={classNames(
          'inline-flex items-center justify-center rounded px-4 py-2 font-semibold',
          'focus:outline-none focus-visible:ring focus-visible:ring-primary-500',
          'shadow-sm',
          'transition-colors duration-75',
          //#region  //*=========== Variants ===========
          [
            variant === 'primary' && [
              'bg-primary-800 text-white',
              'border border-primary-900',
              'hover:bg-primary-700',
              'active:bg-primary-700',
              'disabled:bg-primary-500 disabled:hover:bg-primary-500',
            ],
            variant === 'secondary' && [
              'bg-secondary-800 text-white',
              'border border-secondary-900',
              'hover:bg-secondary-700 hover:text-white',
              'active:bg-secondary-800',
              'disabled:bg-secondary-500 disabled:hover:bg-secondary-500',
            ],
            variant === 'outline' && [
              'text-primary-500',
              'border border-primary-500',
              'hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100',
              'dark:hover:bg-gray-900/90 dark:active:bg-gray-900/90 dark:disabled:bg-gray-900/90',
            ],
            variant === 'ghost' && [
              'text-primary-500',
              'shadow-none',
              'hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100',
              'dark:hover:bg-gray-900 dark:active:bg-gray-900 dark:disabled:bg-gray-900',
            ],
            variant === 'light' && [
              'bg-white text-gray-900',
              'border border-gray-300',
              'hover:bg-gray-100 hover:text-dark',
              'active:bg-white/80 disabled:bg-gray-200',
            ],
            variant === 'dark' && [
              'bg-gray-900 text-white',
              'border border-gray-600',
              'hover:bg-gray-900 active:bg-gray-700 disabled:bg-gray-700',
            ],
          ],
          //#endregion  //*======== Variants ===========
          'disabled:cursor-not-allowed',
          isLoading &&
            'relative text-transparent transition-none hover:text-transparent disabled:cursor-wait',
          className
        )}
        {...rest}
      >
        {isLoading && (
          <div
            className={classNames('absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2', {
              'text-white': ['primary', 'dark'].includes(variant),
              'text-black': ['light'].includes(variant),
              'text-primary-500': ['outline', 'ghost'].includes(variant),
            })}
          >
            <Loader className="animate-spin-slow" />
          </div>
        )}
        {children}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
