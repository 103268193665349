import siteMetadata from 'data/siteMetadata'
import { trackEvent } from 'lib/utils/analytics'
import { useRef, useState } from 'react'
import { Form, FormState } from 'types/Form'

const useNewsletter = () => {
  const inputEl = useRef<HTMLInputElement>(null)
  const botInputEl = useRef<HTMLInputElement>(null)
  const [form, setForm] = useState<FormState>({ state: Form.Initial })

  const subscribe = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setForm({ state: Form.Loading })
    const fromUrl = window?.location?.href

    if (botInputEl.current.value) {
      setForm({
        state: Form.Error,
        message:
          'Looks like this is a bot request, if you feel thats not the case then please submit the form again',
      })
      trackEvent({
        name: 'Newsletter',
        data: { value: inputEl.current.value, status: 'failed (BOT)' },
      })
      return
    }

    try {
      const res = await fetch(`/api/${siteMetadata.newsletter.provider}`, {
        body: JSON.stringify({
          email: inputEl.current.value,
          fromUrl,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })

      const { error } = await res.json()

      if (error) {
        setForm({ state: Form.Error, message: error })
        trackEvent({ name: 'Newsletter', data: { value: inputEl.current.value, status: 'failed' } })
        return
      }

      setForm({ state: Form.Success, message: 'You are now subscribed. 🎉' })
      trackEvent({ name: 'Newsletter', data: { value: inputEl.current.value, status: 'success' } })
      inputEl.current.value = ''
    } catch (e) {
      console.log({ e })
      setForm({ state: Form.Error, message: e.error })
      trackEvent({
        name: 'Newsletter',
        data: { value: inputEl.current.value, status: 'failed (5xx)' },
      })
    }
  }

  return { form, subscribe, inputEl, botInputEl }
}

export default useNewsletter
