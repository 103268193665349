import { classNames } from 'lib/utils/classnames'
import NextImage, { ImageProps } from 'next/image'
import { useState } from 'react'

const Image = ({ className = '', ...rest }: ImageProps) => {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <NextImage
      className={classNames(
        'duration-700 ease-in-out rounded-lg',
        isLoading ? 'grayscale blur-2xl scale-110' : 'grayscale-0 blur-0 scale-100',
        className
      )}
      onLoadingComplete={() => setIsLoading(false)}
      {...rest}
    />
  )
}

export default Image
