import SocialIcon from 'components/icons/social-icons'
import siteMetadata from 'data/siteMetadata'
import { classNames } from 'lib/utils/classnames'
import Link from './Link'
import Newsletter from './Newsletter'

interface Item {
  name: string
  href: string
  isAvailable?: boolean
  icon?: string
}

const { pages } = siteMetadata

const navigation: Record<string, Item[]> = {
  general: [
    { name: 'Home', href: pages.home },
    { name: 'About', href: pages.about },
    { name: 'Blogs', href: pages.blogs },
    { name: 'Projects', href: pages.projects },
    { name: 'Snippets', href: pages.snippets },
  ],
  extra: [
    { name: 'Newsletter', href: pages.newsletter },
    { name: 'Uses', href: pages.uses },
    { name: 'Resume', href: pages.resume },
    { name: 'Tweets', href: pages.tweets },
    { name: 'Tags', href: pages.tags },
    { name: 'Privacy Policy', href: pages.privacyPolicy },
  ],
  social: [
    { name: 'Email', href: `mailto:${siteMetadata.email}`, icon: 'mail' },
    { name: 'Github', href: siteMetadata.github, icon: 'github' },
    { name: 'Twitter', href: siteMetadata.twitter, icon: 'twitter' },
    { name: 'Linkedin', href: siteMetadata.linkedin, icon: 'linkedin' },
    { name: 'Youtube', href: siteMetadata.youtube, icon: 'youtube' },
    { name: 'RSS', href: siteMetadata.rss, icon: 'rss' },
  ],
}

export default function Footer() {
  return (
    <div className="py-8">
      <footer className="space-y-12 mt-10 max-w-5xl mx-auto px-6">
        <hr className="hr" />
        <div className="flex flex-col md:flex-row gap-4 justify-between items-start w-full">
          <div className="flex flex-row gap-20 md:gap-32 justify-start">
            <div>
              <h3 className="text-sm font-semibold tracking-wider uppercase">General</h3>
              <ul className="mt-4 space-y-2">
                {navigation.general.map((item) => (
                  <li key={item.name}>
                    <Link
                      href={item.href}
                      showExternalPreview={false}
                      showExternalIcon={false}
                      className="block text-base text-gray-600 no-underline hover:text-gray-800 dark:hover:text-gray-100 dark:text-gray-200 hover:underline"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold tracking-wider uppercase">Extras</h3>
              <ul className="mt-4 space-y-2">
                {navigation.extra.map((item) => {
                  if (item.isAvailable === false) {
                    return (
                      <li
                        key={item.name}
                        className={classNames(
                          'block text-base text-gray-600 no-underline dark:text-gray-200 opacity-50 cursor-not-allowed'
                        )}
                      >
                        {item.name}
                      </li>
                    )
                  }

                  return (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        showExternalPreview={false}
                        showExternalIcon={false}
                        className="block text-base text-gray-600 no-underline hover:text-gray-800 dark:hover:text-gray-100 dark:text-gray-200 hover:underline"
                      >
                        {item.name}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="mt-8 md:mt-0">
            <div className="flex justify-start">
              <Newsletter size="small" />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 md:flex-row items-center justify-between mt-12">
          <div className="flex items-center md:order-2 order-1 space-x-6">
            {navigation.social.map((item) => (
              <SocialIcon key={item.name} kind={item.icon} href={item.href} size={6} />
            ))}
          </div>
          <p className="md:order-1 order-2 text-base">
            &copy; {new Date().getFullYear()} {siteMetadata.author}
          </p>
        </div>
      </footer>
    </div>
  )
}
