import siteMetadata from 'data/siteMetadata'
import useNewsletter from 'hooks/useNewsletter'
import fetcher from 'lib/utils/fetcher'
import { useTheme } from 'next-themes'
import React, { FunctionComponent, MutableRefObject } from 'react'
import { Mail } from 'react-feather'
import useSWR from 'swr'
import { useRouter } from 'next/router'
import { Form, FormState, INewsletterSize, INewsLetterStats } from 'types/Form'
import Button from './Button'
import TextDataLoader from './loaders/TextDataLoader'

interface IStandardNewsletterProps {
  subscribe: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
  inputEl: MutableRefObject<HTMLInputElement>
  botInputEl: MutableRefObject<HTMLInputElement>
  form: FormState
  buttonTheme: 'dark' | 'light'
}

const StandardNewsletter: FunctionComponent<IStandardNewsletterProps> = ({
  form,
  inputEl,
  botInputEl,
  subscribe,
  buttonTheme,
}) => {
  const { data: newsletterStats } = useSWR<INewsLetterStats>(
    `/api/${siteMetadata.newsletter.provider}`,
    fetcher
  )

  const disabled = form.state === Form.Success || form.state === Form.Loading

  const totalSubscribers = newsletterStats?.totalSubscribers ?? 0
  const error = newsletterStats?.error

  return (
    <div className="border border-gray-200 rounded-lg p-6 dark:border-gray-700 bg-gray-50 dark:bg-gray-800">
      <div className="mb-4 space-y-4">
        <h2 className="pb-1 font-bold text-gray-900 text-xl md:text-2xl dark:text-gray-100 flex items-center gap-2">
          Updates straight in your inbox!{' '}
          <span className="w-5 h-5">
            <Mail />
          </span>
        </h2>
        <p className="mb-0">
          A periodic update about my life, recent blog posts, TIL (Today I learned) related stuff,
          things I am building and more!
        </p>
      </div>

      <form className="flex flex-col sm:flex-row gap-2" onSubmit={subscribe}>
        {/* Bot field */}
        <input
          id="name"
          ref={botInputEl}
          name="name"
          placeholder="do not fill this field"
          type="hidden"
        />
        <div className="relative flex-grow sm:border border-gray-500 group sm:rounded-xl sm:focus-within:ring-1 focus-within:ring-gray-900 dark:focus-within:ring-gray-400 focus-within:border-gray-900 dark:focus-within:border-gray-400">
          <label htmlFor="email-input-inline" className="sr-only">
            Email address
          </label>
          <input
            id="email-input"
            name="email"
            placeholder={
              form.state === Form.Success ? "You're subscribed !  🎉" : 'Enter email address'
            }
            ref={inputEl}
            required
            type="email"
            disabled={disabled}
            autoComplete="email"
            className="block w-full px-4 py-4 sm:pr-32 text-gray-900 dark:text-gray-200 placeholder-gray-900 dark:placeholder-gray-400 bg-transparent border border-gray-400 dark:border-gray-100 outline-none focus:border-gray-900 dark:focus:border-gray-200 focus:ring-1 focus:ring-gray-900 dark:focus:ring-gray-200 rounded-xl sm:border-none sm:focus:ring-0 sm:focus:border-transparent"
          />
          <div className="mt-4 sm:mt-0 sm:absolute sm:inset-y-0 sm:right-0 sm:flex sm:items-center sm:pr-2">
            <Button
              type="submit"
              variant={buttonTheme}
              isLoading={form.state === Form.Loading && !error}
              disabled={disabled}
              className="rounded-lg w-full"
            >
              {form.state === Form.Success ? 'Thank you!' : 'Subscribe'}
            </Button>
          </div>
        </div>
      </form>
      {form.state === Form.Success ? (
        <div className="pt-2 text-sm text-green-500 w-72 sm:w-96 dark:text-green-400">
          {form.message}
        </div>
      ) : form.state === Form.Error ? (
        <div className="pt-2 text-sm text-red-500 w-72 sm:w-96 dark:text-red-400">
          {form.message}
        </div>
      ) : error !== null ? (
        <div className="pt-2 text-sm text-red-500 w-72 sm:w-96 dark:text-red-400">{error}</div>
      ) : (
        <div className="flex flex-row justify-between mt-4 text-sm">
          <p>
            {totalSubscribers > 0 ? (
              `Join ${totalSubscribers.toLocaleString()} other subscribers`
            ) : (
              <TextDataLoader />
            )}
          </p>
          <p className="italic">No spam - unsubscribe at any time!</p>
        </div>
      )}
    </div>
  )
}

const InlineNewsletter: FunctionComponent<
  Pick<IStandardNewsletterProps, 'form' | 'inputEl' | 'botInputEl' | 'subscribe' | 'buttonTheme'>
> = ({ form, inputEl, botInputEl, subscribe, buttonTheme }) => {
  const disabled = form.state === Form.Success || form.state === Form.Loading

  return (
    <div className="space-y-4">
      <h3 className="my-0 text-sm font-semibold tracking-wider uppercase">
        Subscribe to our newsletter
      </h3>
      <p className="text-base">The latest articles, and resources, sent to your inbox directly!</p>
      <form className="flex flex-col sm:flex-row gap-2" onSubmit={subscribe}>
        {/* Bot field */}
        <input
          id="name"
          ref={botInputEl}
          name="name"
          placeholder="do not fill this field"
          type="hidden"
        />
        <div className="relative flex-grow sm:border border-gray-500 group sm:rounded-xl sm:focus-within:ring-1 focus-within:ring-gray-900 dark:focus-within:ring-gray-400 focus-within:border-gray-900 dark:focus-within:border-gray-400">
          <label htmlFor="email-input-inline" className="sr-only">
            Email address
          </label>
          <input
            id="email-input-inline"
            name="email"
            placeholder={
              form.state === Form.Success ? "You're subscribed !  🎉" : 'Enter email address'
            }
            ref={inputEl}
            required
            type="email"
            disabled={disabled}
            autoComplete="email"
            className="block w-full px-4 py-4 sm:pr-32 text-gray-900 dark:text-gray-200 placeholder-gray-900 dark:placeholder-gray-400 bg-transparent border border-gray-400 dark:border-gray-100 outline-none focus:border-gray-900 dark:focus:border-gray-200 focus:ring-1 focus:ring-gray-900 dark:focus:ring-gray-200 rounded-xl sm:border-none sm:focus:ring-0 sm:focus:border-transparent"
          />
          <div className="mt-4 sm:mt-0 sm:absolute sm:inset-y-0 sm:right-0 sm:flex sm:items-center sm:pr-2">
            <Button
              type="submit"
              variant={buttonTheme}
              isLoading={form.state === Form.Loading}
              disabled={disabled}
              className="rounded-lg w-full"
            >
              {form.state === Form.Success ? 'Thank you!' : 'Subscribe'}
            </Button>
          </div>
        </div>
      </form>
      {form.state === Form.Error && (
        <div className="text-sm text-red-500 w-72 sm:w-96 dark:text-red-400">{form.message}</div>
      )}
      {form.state === Form.Success && (
        <div className="text-sm text-green-500 w-72 sm:w-96 dark:text-green-400">
          {form.message}
        </div>
      )}
    </div>
  )
}

const Newsletter: FunctionComponent<{ size?: INewsletterSize }> = ({ size = 'large' }) => {
  const router = useRouter()
  const { form, inputEl, botInputEl, subscribe } = useNewsletter()
  const { theme } = useTheme()
  const buttonTheme = !theme ? 'light' : theme === 'dark' ? 'light' : 'dark'

  if (router?.query?.email) {
    inputEl.current.value = router.query.email as string
  }

  if (size === 'small') {
    return (
      <InlineNewsletter
        form={form}
        inputEl={inputEl}
        botInputEl={botInputEl}
        subscribe={subscribe}
        buttonTheme={buttonTheme}
      />
    )
  }

  return (
    <StandardNewsletter
      form={form}
      inputEl={inputEl}
      botInputEl={botInputEl}
      subscribe={subscribe}
      buttonTheme={buttonTheme}
    />
  )
}

export default Newsletter
