import { classNames } from 'lib/utils/classnames'
import headerNavLinks from 'data/headerNavLinks'
import Logo from 'data/logo.svg'
import { useKBar } from 'kbar'
import { useRouter } from 'next/router'
import { useTheme } from 'next-themes'
import React, { FunctionComponent, KeyboardEventHandler, useEffect, useState } from 'react'
import { Command, Sun, Moon } from 'react-feather'
import toast from 'react-hot-toast'
import Link from './Link'
import MobileNav from './MobileNav'
import { trackEvent } from 'lib/utils/analytics'
import siteMetadata from 'data/siteMetadata'

const NavLinkItem: FunctionComponent<{ href: string; title: string }> = ({ href, title }) => {
  const [isActive, setIsActive] = useState(false)
  const router = useRouter()

  useEffect(() => {
    setIsActive(router.asPath === href || router.pathname === href)
  }, [router, href])

  return (
    <Link
      key={title}
      href={href}
      className={classNames(
        'px-4 py-2 font-medium text-gray-900 dark:text-gray-100 hover:bg-white dark:hover:bg-gray-700 rounded-full transition-colors duration-300',
        isActive && 'bg-gray-50 dark:bg-gray-700 hover:bg-gray-50 hover:dark:bg-gray-600'
      )}
    >
      {title}
    </Link>
  )
}

const Header: FunctionComponent = (): JSX.Element => {
  const kbar = useKBar()
  const { resolvedTheme, setTheme } = useTheme()

  const keyDownHandler: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.code === 'Space') {
      event.preventDefault()
      event.stopPropagation()
      kbar.query.toggle()
      toast('You can also press ⌘K to open the menu!')
    }
  }

  const toggleTheme = () => {
    const newTheme = resolvedTheme === 'light' ? 'dark' : 'light'
    setTheme(newTheme)
    trackEvent({ name: 'Theme Changed', data: { mode: newTheme } })
  }

  return (
    <div className="flex sm:justify-center my-6 z-[999]">
      <nav className="w-fit hidden sm:flex items-center justify-center px-4 py-2 border border-gray-400 dark:border-gray-700 bg-gray-200 dark:bg-gray-800 rounded-full bg-opacity-80 backdrop-filter backdrop-blur-lg backdrop-saturate-150">
        <div className="flex items-center gap-1 sm:gap-2 text-md leading-5 divide-x dark:divide-gray-600 divide-gray-400">
          <div className="flex gap-1 sm:gap-2">
            {headerNavLinks.map((link) => (
              <NavLinkItem key={link.title} href={link.href} title={link.title} />
            ))}
          </div>
          <div className="flex">
            <div
              className="p-2 ml-2 text-gray-900 dark:text-gray-100 print:hidden hover:bg-white hidden md:flex flex-row gap-1 items-center dark:hover:bg-gray-700 rounded-full transition-colors duration-300"
              onClick={() => toggleTheme()}
              role="button"
            >
              {resolvedTheme === 'dark' ? (
                <Sun size={18} aria-label="Toggle light mode" />
              ) : (
                <Moon size={18} aria-label="Toggle dark mode" />
              )}
            </div>
            <div
              className="p-2 ml-2 text-gray-900 dark:text-gray-100 print:hidden hover:bg-white hidden md:flex flex-row gap-1 items-center dark:hover:bg-gray-700 rounded-full transition-colors duration-300"
              onClick={() => kbar.query.toggle()}
              onKeyDown={keyDownHandler}
              role="button"
            >
              <Command size={18} aria-label="Menu" />
            </div>
          </div>
        </div>
      </nav>
      <div className="sm:hidden flex justify-between w-full items-center ml-5">
        <Link href={siteMetadata.pages.home} aria-label={`${siteMetadata.author}`}>
          <div className="flex items-center justify-between">
            <div className="mr-3">
              <Logo />
            </div>
          </div>
        </Link>
        <div className="flex">
          <div
            className="p-2 text-gray-900 dark:text-gray-100 print:hidden hover:bg-white flex-row gap-1 items-center dark:hover:bg-gray-700 rounded-full transition-colors duration-300"
            onClick={() => toggleTheme()}
            role="button"
          >
            {resolvedTheme === 'dark' ? (
              <Sun size={24} aria-label="Toggle light mode" />
            ) : (
              <Moon size={24} aria-label="Toggle dark mode" />
            )}
          </div>
          <MobileNav />
        </div>
      </div>
    </div>
  )
}

export default Header
